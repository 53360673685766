<template>
  <!-- prettier-ignore -->
  <index-content>
    <template #options>
      <nb-download :url="OPERATOR_ACCESS_CSV_URL" :params="condition" />
    </template>
    <search-condition searchable :loading="searching" @do-search="search">
      <ns-company v-model="condition.companyId" all-option />
      <n-field-enum v-model="condition.level" enum-name="AuditLevel" label="level" all-option />
      <n-field-selection v-model="condition.operatorId" selection-name="admin.operator" label="operator" all-option />
      <n-field-enum v-model="condition.clientType" enum-name="ClientType" label="clientType" all-option />
      <n-field-text v-model="condition.action" label="action" />
      <n-field-text v-model="condition.domain" label="domain" />
      <n-field-datetime v-model="condition.from" label="from" />
      <n-field-datetime v-model="condition.to" label="to" />
    </search-condition>
    <n-table :total="total" :page-no="pageNo" :page-size="pageSize" :searched="searched" :searching="searching" :has-result="hasResult" @on-page-no="onPageNo" @on-page-size="onPageSize">
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th class="n-table-mark"></th>
            <th class="col-weight-2">{{ $t('label.company') }}</th>
            <th class="col-weight-2">{{ $t('label.operator') }}</th>
            <th class="col-weight-2">{{ $t('label.clientType') }}</th>
            <th class="col-weight-2">{{ $t('label.action') }}</th>
            <th class="col-weight-2">{{ $t('label.domain') }}</th>
            <th class="col-weight-2">{{ $t('label.target') }}</th>
            <th class="col-weight-5">{{ $t('label.resource') }}</th>
            <th class="col-weight-5">{{ $t('label.parameters') }}</th>
            <th class="n-table-timestamp">{{ $t('label.accessTime') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>
              <i v-if="row.level === $enums.AuditLevel.INFO" class="n-success fas fa-info-circle"></i>
              <i v-if="row.level === $enums.AuditLevel.WARN" class="n-warning fas fa-info-circle"></i>
              <i v-if="row.level === $enums.AuditLevel.ERROR" class="n-error fas fa-info-circle"></i>
            </td>
            <td v-selection:[`company.company`]="row.companyId"></td>
            <td class="n-ellipsis" :title="row.loginId">{{ row.loginId }}</td>
            <td v-enums:ClientType="row.clientType"></td>
            <td class="n-ellipsis" :title="row.action">{{ row.action }}</td>
            <td class="n-ellipsis" :title="row.domain">{{ row.domain }}</td>
            <td class="n-ellipsis" :title="row.target">{{ row.target | hyphen }}</td>
            <td class="n-ellipsis" :title="row.resource">{{ row.resource }}</td>
            <td class="n-ellipsis" :title="row.parameter">{{ row.parameter | hyphen }}</td>
            <td>{{ row.accessTime | datetime }}</td>
          </tr>
        </tbody>
      </table>
    </n-table>
  </index-content>
</template>

<script>
import OperatorView from './view';
import PaginateMixin from '@/mixins/paginate-mixin';
import { fetch, OPERATOR_ACCESS_CSV_URL } from '@/api/operator/operator-operator-access';

export default OperatorView.extend({
  name: 'OperatorOperatorAccess',
  mixins: [PaginateMixin],
  data() {
    return {
      condition: {
        companyId: 0,
        level: 0,
        operatorId: 0,
        clientType: 0,
        action: null,
        domain: null,
        from: this.oneWeekAgo,
        to: this.eod,
      },
      OPERATOR_ACCESS_CSV_URL,
    };
  },
  methods: {
    doSearch(params) {
      let p = { ...params, ...this.condition };
      return fetch(p);
    },
    parse(rows) {
      this.records = rows.records;
    },
  },
});
</script>
